import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import './style.css';
// import GitHubIcon from '@material-ui/icons/GitHub';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Footer from './Footer';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Cancel from '@material-ui/icons/Cancel';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import LockOpen from '@material-ui/icons/LockOpen';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';

// import Main from './Main';
// import Sidebar from './Sidebar';


const mainFeaturedPost = {
    title: 'e-Sic',
    description:
        "Sistema eletrônico de informação ao cidadão.",
    image: "https://pinheiro.ma.gov.br/wp-content/uploads/2025/02/pinheiro-1.jpg",
    imgText: 'main image description',
};

const featuredPosts = [
    {
        title: 'Portal da Transparência',
        description:
            'Acesso ao sistema externo do portal da transparência do município.',
    },
    {
        title: 'e-Sic',
        description:
            'Através deste formulário, pessoas físicas ou jurídicas podem encaminhar pedidos de acesso à informação.',
    }
];

export default function Blog() {
    // const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openResetSenha, setOpenResetSenha] = useState(false);
    const [info, setInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [mask, setMask] = useState("");
    const [senha, setSenha] = useState('');
    let history = useHistory();
    // const classes = useStyles();

    useEffect(() => {
      const authenticated = localStorage.getItem('@tokenTransparencia');
      if(authenticated){
        toast.success(`Voaê está logado no sistema, será redirecionado.`, {
            position: 'bottom-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });  
        setTimeout(function () {
            // history.push('/portal/esic')
        }, 6000);
      }
    }, []);

    function rota(rota){
      history.push(rota);
    }
    const handleOpen = (value) => {
      setOpen(true);
      if(value === 1){
        setInfo(value)
      }else if(value === 2){
        setInfo(value)
      }
    };
    const handleOpenResetSenha = (value) => {
      setOpenResetSenha(true);
    };

    function handleChangeCpfCnpj(event) {
        setCpfCnpj(event.target.value);
    }

    function handleChangeSenha(event) {
        setSenha(event.target.value);
    }
  
    const handleClose = () => {
      setOpen(false);
    };
    const handleCloseResetSenha = () => {
      setOpenResetSenha(false);
    };

    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };

    async function login(event) {
        event.preventDefault();
        if(cpfCnpj === ''){
          toast.error(`O CPF ou CNPJ é necessário.`, {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
          }); 
          return;
        }
        if(senha === ''){
          toast.error(`Sua senha é necessário.`, {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
          }); 
          return;
        }
        setLoading(true);
        try {
            const response = await api.post('/sessions', {
                cpf_cnpj: cpfCnpj.trim(),
                password: senha,
            });
            console.log('Sucesso, ', response.data.token);
            //Alert.alert("Sucesso", response.data.token);
            localStorage.setItem('@tokenTransparencia', response.data.token);
            // buscarPerfil(response.data.token);
            // await AsyncStorage.setItem('@tokenEmpresa', response.data.token);
            setLoading(false);
            history.push('/portal/esic');
        } catch (error) {
            setLoading(false);
            // setOpenAlert(true);
            // setTypeAlert('error');
            // setMsgAlert('Login ou senha, incorretos!');
            console.log('Error', error, error.response);
            if(error.response.data[0].field === 'cpf_cnpj' && error.response.data[0].message === 'Cannot find user with provided cpf_cnpj'){
              toast.error(`O CPF ou CNPJ nao foi encontrado.`, {
                  position: 'bottom-left',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
              });  
            }
            if(error.response.data[0].field === 'password' && error.response.data[0].message === 'Invalid user password'){
              toast.error(`Você está usando uma senha inválida.`, {
                  position: 'bottom-left',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
              });  
            }
        }
    }

    const body = (
      <div className={"paperModal"}>
        <div className="simple-modal-title">
          {info === 1 ? "Como fazer o cadastro" : "Como fazer um pedido"}
          <Cancel onClick={handleClose} style={{color: '#fff', cursor: 'pointer'}} color="action" />
        </div>
        <div className="simple-modal-conteudo">
          {info === 1 && (
            <>
              <p>
                1. Entre na página do Lei de Acesso e clique no link "Cadastre-se", localizado à direita da página, dentro da caixa de login;
              </p>
              <p>
                2. A tela seguinte é a de cadastro do solicitante, nela o usuário irá preencher os dados pessoais e em seguida clicar no botão salvar ao final da página para que o seu cadastro seja efetuado;
              </p>
              <p>
                3. O usuário receberá uma notificação via e-mail, portanto é de suma importância que na tela de cadastro de solicitante o usuário insira um e-mail válido. Obs: Ao entrar na caixa de entrada do seu webmail se não encontrar o e-mail de confirmação, entre na caixa “Lixo” se você inseriu todos os dados de forma correta possivelmente o e-mail de confirmação poderá estar lá;
              </p>
              <p>
                4. No e-mail de confirmação, estará contido um link no qual o usuário deverá clicar, para que só assim seu cadastro seja validado;
              </p>
              <p>
                5. O link que o solicitante clicou no passo anterior irá redirecioná-lo (em nova janela) para a página confirmação de cadastro;
              </p>
            </>
          )}
          {info === 2 && (
            <>
              <p>
              1. Faça seu cadastro no sistema, e acesse o sistema com seu nome de usuário e senha;
              </p>
              <p>
                2. Acesse a aba "Fazer Solicitação" e descreva detalhadamente seu pedido;
              </p>
              <p>
                3. Abaixo, escolha a forma de retorno que deseja apos informar seu pedido;
              </p>
              <p>
                4. Apos descrever sua solicitação e definir a forma de retorno, o usuário deve clicar em enviar para registrar sua solicitação, uma tela de confimação deve aparecer;
              </p>
              <p>
                5. Acesse a aba "Solicitações Realizadas" para acompanhar suas solicitações e aguarde ate que seu pedido seja respondido.
              </p>
            </>
          )}
        </div>
      </div>
    );

    async function solicitarSenha() {
      setLoading(true)

      await api
          .get(`/reset-senha/${cpfCnpj}`, {
            headers: {
              "content-type": "application/json",
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Resetando senha", res.data)
              setLoading(false);
              setOpenResetSenha(false)
              toast.info(res.data, {
                position: "bottom-left",
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          })
          .catch(error => {
            setLoading(false);
            // console.log("error: ", error, error.response)
            // console.log("response: ", error.response.data.message)
            toast.error(`Ops! erro ao solicitar nova senha, ${error.response.data.message}`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

          });
    }

    const handleClick = (link) => {

    }
    return (
        <React.Fragment>
            <CssBaseline />
            <Header />
            <Container maxWidth="lg">
              <Grid container style={{marginBottom: 10}} spacing={3}>
                <Grid item xs={12} md={12}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <a color="inherit" href="https://pinheiro.ma.gov.br/" title="Site">Site</a>
                    <a color="inherit" href="/" title="Transparência">Transparência</a>
                    <span color="textPrimary">E-Sic</span>
                  </Breadcrumbs>
                </Grid>
              </Grid>
                <main>
                    <MainFeaturedPost post={mainFeaturedPost} />
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Paper className={'paperHeader'}>
                          <h2>Para continuar, faça seu login ou cadastro</h2>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Paper className="paper">
                          <div className="headerPaperTitle">
                            Bem-Vindo
                          </div>
                          <div className="paperConteudo">
                            <p>O e-SIC (Sistema Eletrônico do Serviço de Informações ao Cidadão) permite que qualquer pessoa, física ou jurídica, encaminhe pedidos de acesso a informação para órgãos e entidades do Poder Executivo Federal.</p>
                            <p>Por meio do sistema, além de fazer o pedido, será possível acompanhar o prazo pelo número de protocolo gerado e receber a resposta da solicitação por e-mail; entrar com recursos, apresentar reclamações e consultar as respostas recebidas. O objetivo é facilitar o exercício do direito de acesso às informações públicas.</p>
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Paper className="paper">
                          <div className="headerPaperTitle">
                            Informações
                          </div>
                          <div className="paperConteudo">
                            <div className="manual">
                              <div onClick={() => {handleOpen(1)}}>Como fazer o cadastro</div>
                              <div onClick={() => {handleOpen(2)}}>Como fazer um pedido</div>
                              <div onClick={() => {rota('/estatistica')}}>Estatísticos e-Sic</div>
                              <div>
                                <a target="_blank" style={{textDecoration: 'none', color:'#fff'}} href="http://pinheiro.ma.gov.br/leis/L12527.pdf">
                                  Lei de Acesso
                                </a>
                              </div>
                              <div>
                                <a target="_blank" style={{textDecoration: 'none', color:'#fff'}} href="https://pinheiro.ma.gov.br/rol-de-informacoes">
                                  Rol de Informações
                                </a>
                              </div>
                              <div>
                                <a target="_blank" style={{textDecoration: 'none', color:'#fff'}} href="https://pinheiro.ma.gov.br/sic/">
                                  SIC (Físico)
                                </a>
                              </div>
                            </div>
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Paper className="paper">
                          <div className="formLogin">
                          <div className="formLoginHeader">
                            Acesse o sistema
                          </div>
                          <form onSubmit={login} noValidate>
                            <FormControl fullWidth className="formControl" variant="outlined">
                              {/* <InputLabel htmlFor="outlined-setCpfCnpj">Seu CPF ou CNPJ</InputLabel> */}
                              <CpfCnpj
                                className="customizedInput"
                                placeholder="Digite um CPF ou CNPJ"
                                type="tel"
                                value={cpfCnpj}
                                onChange={(event, type) => {
                                  setCpfCnpj(event.target.value);
                                  setMask(type === "CPF");
                                }}
                              />
                              {/* <OutlinedInput
                                id="outlined-setCpfCnpj"
                                value={cpfCnpj}
                                required
                                onChange={handleChangeCpfCnpj}
                                startAdornment={
                                  <InputAdornment position="start"><AssignmentInd color="action" /></InputAdornment>
                                }
                                label={'Seu CPF ou CNPJ'}
                              /> */}
                            </FormControl>
                            <FormControl fullWidth className="formControl"  variant="outlined">
                              <InputLabel htmlFor="outlined-senha">Senha de acesso</InputLabel>
                              <OutlinedInput
                                id="outlined-senha"
                                value={senha}
                                required
                                onChange={handleChangeSenha}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <LockOpen color="action" />
                                  </InputAdornment>
                                }
                                label={'Senha de acesso'}
                              />
                            </FormControl>
                            <div className="btns">
                              <Button type="submit" variant="contained" color="primary" className={"btnEntrar"}>
                                Entrar
                              </Button>
                              <Button onClick={() => {rota('/cadastro-unico')}} variant="contained" color="primary" className={"btnEntrar"}>
                                Cadastro único
                              </Button>
                            </div>
                          </form>
                          <a style={{cursor: 'pointer', padding: 5}} onClick={() => {handleOpenResetSenha()}}>Esqueci a senha</a>
                          </div>
                        </Paper>
                      </Grid>
                      {/* <Grid item xs={12} md={12}>
                        <Paper>xs=3</Paper>
                      </Grid> */}
                    </Grid>
                </main>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  className={"modal"}
                >
                  {body}
                </Modal>
                <Modal
                  open={openResetSenha}
                  onClose={handleCloseResetSenha}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  className={"modal"}
                >
                  <div className={"paperModal"}>
                    <div className="simple-modal-title">
                      Solicitar nova senha
                      <Cancel onClick={handleCloseResetSenha} style={{color: '#fff', cursor: 'pointer'}} color="action" />
                    </div>
                    <div className="simple-modal-conteudo">
                      <FormControl fullWidth className="formControl" variant="outlined">
                        <CpfCnpj
                          className="customizedInput"
                          placeholder="Digite um CPF ou CNPJ"
                          type="tel"
                          value={cpfCnpj}
                          onChange={(event, type) => {
                            setCpfCnpj(event.target.value);
                            setMask(type === "CPF");
                          }}
                        />
                      </FormControl>
                      <Button onClick={solicitarSenha} style={{marginTop: 10}} variant="contained" color="secondary">
                        Enviar
                      </Button>
                    </div>
                  </div>
                </Modal>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Container>
            {loading && (
              <div className="loading">
                <CircularProgress />
              </div>
            )}
            <Footer />
        </React.Fragment>
    );
}
