import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import './style.css';
// import GitHubIcon from '@material-ui/icons/GitHub';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import FeatureLeis from './FeatureLeis';
import Footer from './Footer';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';

// import Main from './Main';
// import Sidebar from './Sidebar';


const mainFeaturedPost = {
    title: 'Serviços do município',
    description:"Listamos aqui todos os serviços do município",
    image: "https://pinheiro.ma.gov.br/wp-content/uploads/2025/02/pinheiro-1.jpg"
};

export default function Blog() {
    let history = useHistory();
    // const classes = useStyles();

    function rota(rota){
      history.push(rota);
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Header />
            <Container maxWidth="lg">
              <Grid container style={{marginBottom: 10}} spacing={3}>
                <Grid item xs={12} md={12}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <a color="inherit" href="https://pinheiro.ma.gov.br/" title="Site">Site</a>
                    <span color="textPrimary">Transparência</span>
                  </Breadcrumbs>
                </Grid>
              </Grid>
                <main>
                    <MainFeaturedPost post={mainFeaturedPost} />
                    <Grid container spacing={3}>
                        {/* {featuredPosts.map((post) => (
                            <FeaturedPost key={post.title} post={post} />
                        ))} */}
                        <Grid item xs={12} md={8}>
                          <Grid container spacing={3}>
                            <FeaturedPost rota={rota} />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Grid container spacing={3}>
                            <FeatureLeis />
                          </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <Paper >xs=3</Paper>
                      </Grid>
                      <Grid item xs={3}>
                        <Paper>xs=3</Paper>
                      </Grid>
                      <Grid item xs={3}>
                        <Paper>xs=3</Paper>
                      </Grid>
                      <Grid item xs={3}>
                        <Paper>xs=3</Paper>
                      </Grid>
                    </Grid> */}
                </main>
            </Container>
            <Footer />
        </React.Fragment>
    );
}
